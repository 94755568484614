
<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      transition="dialog-bottom-transition"
      :max-width="dialogWidth"
      scrollable
    >
      <v-card v-if="item">
        <v-card-title class="pa-0">
          <v-toolbar
          >
            <v-btn
              icon
              @click="dialog = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>
              {{ item.persona.fullname }}
            </v-toolbar-title>
          </v-toolbar>
        </v-card-title>
        <v-card-text>
          <v-list
            two-line
            subheader
          >
            <v-subheader>{{ $t('app.general.information') }}</v-subheader>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="d-flex justify-center">
                  <v-img
                    v-if="item.persona.fotografia"
                    :src="item.persona.fotografia"
                    :lazy-src="preloader"
                    max-width="350"
                  ></v-img>
                  <template v-else>
                    {{ $t('app.general.undefined') }}
                  </template>
                </v-list-item-title>
                <v-list-item-subtitle>{{ $t('app.headers.photo') }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  {{ item.persona.fullname }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ $t('app.headers.name') }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  {{ item.nombre_persona_responsable }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ $t('app.headers.responsible_name') }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  {{ item.peso }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ $t('app.headers.weight') }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  {{ item.estatura }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ $t('app.headers.height') }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  {{ item.tipo_identificacion }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ $t('app.headers.id_type') }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  {{ item.numero_identificacion }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ $t('app.headers.id_number') }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  {{ item.identificacion_verificada ? $t('app.general.yes') : $t('app.general.no') }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ $t('app.headers.id_verified') }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
  export default {
    name: 'JugadoresDetail',
    data () {
      return {
        dialog: false,
        item: false,
      }
    },
    mounted() {
      EventBus.$on('jugadores-detail', (item) => {
        this.item = item
        this.dialog = true
      })
    },
  }
</script>

<style scoped>

</style>
